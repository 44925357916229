import React from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import { Row } from "react-bootstrap";

class ShowroomCreate extends Form {
  state = {
    data: {
      name: "",
      address: "",
      city: "",
      telephone: "",
      telephoneSecondary: "",
    },
    errors: {},
    cities: [],
    citiesLoaded: false,
  };

  apiPath = "/showrooms";
  title = "Ajouter un showroom";

  schema = {
    name: Joi.string()
      .required()
      .min(2)
      .max(250)
      .label("Nom"),
    address: Joi.string()
      .required()
      .min(2)
      .max(200)
      .label("Adresse"),
    code: Joi.string()
      .min(2)
      .max(200)
      .label("Code")
      .allow(null, ""),

    city: Joi.string().label("City"),
    telephone: Joi.string()
      .required()
      .min(10)
      .max(14)
      .label("Téléphone"),
    telephoneSecondary: Joi.string()
      .allow(null, "")
      .label("Téléphone (alternatif)"),
  };

  showroomHelper;

  constructor(props) {
    super(props);
    this.showroomHelper = new ApiResourceHelper(this.apiPath);
  }

  componentDidMount() {
    this.getCities();
  }

  getPrevious = () => {
    const { history } = this.props;
    history.goBack();
  };

  getCities = async () => {
    try {
      const cityApiPath = "/cities?published=1";
      const cityHelper = new ApiResourceHelper(cityApiPath);
      const { data: cities } = await cityHelper.findAll();
      this.setState({ cities, citiesLoaded: true });
    } catch (ex) {}
  };

  getCityList = () => {
    const { cities } = this.state;

    const cityList = [];

    if (cities && Array.isArray(cities["hydra:member"])) {
      cities["hydra:member"].map((city) => {
        cityList.push({
          key: `${city.name}`,
          value: city["@id"],
        });
        return true;
      });
    }
    return cityList;
  };

  doSubmit = async () => {
    console.log("Submitted");

    await this.showroomHelper.create(this.state.data);
    this.props.history.replace("/showrooms");
  };

  render() {
    return (
      <div className={"no-gutters row mt-lg-5"}>
        <div className={"pr-lg-2 col-lg-8"}>
          <div className={"mb-3 card"}>
            <div className={"card-header"}>
              <h5 className="mb-0">{this.title} </h5>
            </div>
            <div className={"bg-light card-body"}>
              <form onSubmit={this.handleSubmit}>
                {this.renderInput("name", "text", "Nom")}
                <Row>
                  <div className="col-lg-6">
                    {this.renderSelect("city", "Ville", this.getCityList())}
                  </div>
                  <div className={"col-lg-6"}>
                    {this.renderInput("code", "text", "Code")}
                  </div>
                  <div className={"col-lg-6"}>
                    {this.renderInput("address", "text", "Adresse")}
                  </div>
                </Row>

                <Row>
                  <div className={"col-lg-6"}>
                    {this.renderInput("telephone", "text", "Téléphone")}
                  </div>

                  <div className={"col-lg-6"}>
                    {this.renderInput(
                      "telephoneSecondary",
                      "text",
                      "Téléphone (Alternatif)"
                    )}
                  </div>
                </Row>

                <div className={"btn-group"}>
                  <button
                    onClick={() => this.getPrevious()}
                    className="btn btn-secondary mt-3"
                  >
                    Annuler
                  </button>

                  {this.renderButton("Save")}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShowroomCreate;
